import React from 'react'
import Layout from '../components/layout'
import LegalWrapper from '../components/legal/legalWrapper'

const metaData = {
  title: 'Privacy & Cookie Policy | D E W Y N T E R S',
}

const PrivacyCookies = () => (
  <Layout title={metaData.title}>
    <LegalWrapper title="Privacy &amp; Cookies">
      <section>
        <h2>
          <strong>Introduction</strong>
        </h2>
        <p>
          This website, controlled by Dewynters Limited. Dewynters Limited is
          the Data Controller, for the purposes of Data Protection Law, when
          your personal information is processed as a result of your visit to
          this website.
        </p>
        <p>
          We are committed to providing you with relevant information about, and
          control over, how we process your personal information.
        </p>
        <p>
          This policy explains what personal information we may record, why we
          do so, how you may control the use of your information and how we
          protect your information. Details of your legal rights and how to
          exercise them are also set out below.
        </p>
        <p>
          This policy was last updated on 26/03/2019. Any future changes to our
          policy will be reflected&nbsp;
          <a href="https://www.dewynters.com/privacy/#cookie-policy">HERE</a>.
        </p>
        <h3>
          <strong>Contacting us</strong>
        </h3>
        <p>
          Questions, comments and requests regarding this privacy policy are
          welcomed and should be addressed by email to&nbsp;
          <a href="mailto:info@dewynters.com">info@dewynters.com</a>
        </p>
        <h3>What Does This Policy Cover?</h3>
        <p>
          We process your personal information gathered on this website in order
          to tailor our marketing to your needs and to improve our website for
          future customers. The specific purposes for which we may process
          information, and the lawful basis on which we do so in each case, are
          set out below.
        </p>
        <h3>
          <strong>Tailoring this website to your needs</strong>
        </h3>
        <p>
          We want to make our website easy to personalise. We use preference
          cookies to store your preferences, such as website language selection,
          so that you do not need to make the same selections every time you
          visit our website. These cookies, stored by your browser on your
          device, will retain your preference choices [for 30 days, until you
          delete them]. We record your consent to store cookies when you first
          visit the site and click on the cookie notice, and we provide details
          of how to change your settings&nbsp;
          <a href="https://www.dewynters.com/privacy/#cookie-policy">HERE</a>
        </p>
        <h3>
          <strong>Improving the effectiveness of our website</strong>
        </h3>
        <p>
          We want to make sure that our website enables people to learn about
          the show and to book tickets and that the site meets the needs of our
          customers and is easy to use. To do this, we need to understand how
          people use this site, where they spend time and which pages they find
          less useful. We use Google Analytics platform to understand how you
          and other visitors use our website, for example recording in what
          order pages are visited and how long is spent on each, so that we may
          improve the website in future. [Google Analytics platform&rsquo;s
          privacy policy may be found&nbsp;
          <a
            href="https://policies.google.com/privacy?hl=en"
            target="_blank"
            rel="noreferrer noopener"
          >
            here
          </a>
          ]. We record your consent to store site analytics cookies when you
          first visit the site and click on the cookie notice. The lawful basis
          on which we process these analytics cookies is our legitimate interest
          in improving our website as we believe that the use of such cookies is
          not intrusive.
        </p>
        <h3>
          <strong>
            Targeting and measuring the effectiveness of our digital advertising
          </strong>
        </h3>
        <p>
          We know that many people visit a show site several times to learn
          about the show and dates when tickets are available before deciding to
          book tickets. We want to make sure that our digital advertisements on
          other websites and social media are seen by people likely to be
          interested in the show, including by visitors to this site who have
          not yet booked. We also want to understand the effectiveness of our
          advertising in encouraging visits to the website and ticket bookings
          so that we may improve it in future for example by reviewing the
          choice of advertising design and the websites and advertising networks
          used to reach possible customers. Achieving this may involve showing
          different versions of advertisements to different customers to measure
          the responses generated by each. We may use available browsing
          history, including visits to this website, to identify people to whom
          we wish to show advertisements on websites operated by third parties
          and to record how many visitors are referred to our site from other
          sites. We use Sizmek advertising tracking cookies to achieve this and
          process these cookies on the basis of legitimate interest. We believe
          that showing relevant advertisements is in your interest as well as
          ours and we do not believe that such personalisation is generally
          considered intrusive.] We respect any decision not to participate in
          personalised advertising, in which case third party sites may show you
          non-personalised advertising and you may still see advertisements for
          our show. We record your consent to store advertising tracking cookies
          when you first visit the site and click on the cookie notice. We use
          Sizmek to track our advertising. Details of how long your data is
          stored and how transfers outside the EEA are protected may be found in
          their&nbsp;
          <a
            href="https://www.sizmek.com/privacy-policy/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Privacy Policy
          </a>
          . Our tracking cookie information is not shared with third parties for
          their own use, though we may use it to reconcile advertising costs
          with the publishers of third party sites.
        </p>
        <h3>
          <strong>Data Transfers</strong>
        </h3>
        <p>
          The personal information collected while you use this website may be
          transferred to countries outside the European Economic Area (EEA).
          This may happen, for example, when the computer servers used to host
          the website or our advertising and marketing systems are located in a
          country outside the EEA. If we transfers your personal information
          outside of the EEA in this way, we will take steps to ensure that your
          privacy rights continue to be appropriately protected.
        </p>
        <h3>
          <strong>Your rights</strong>
        </h3>
        <p>
          We want to be transparent about how we process your data and to ensure
          that you can exercise your legal rights:
        </p>
        <ul>
          <li>
            To be informed &ndash; via this Privacy Policy which sets out how we
            collect and process data
          </li>
          <li>
            To access your data &ndash; please contact&nbsp;
            <a href="mailto:info@dewynters.com">info@dewynters.com</a>&nbsp;if
            you wish to confirm what data we hold about you
          </li>
          <li>
            To have your data corrected - please contact&nbsp;
            <a href="mailto:info@dewynters.com">info@dewynters.com</a>&nbsp;if
            you feel that data held is incorrect and want this rectified
          </li>
          <li>
            To have processing of your data ceased - please contact&nbsp;
            <a href="mailto:info@dewynters.com">info@dewynters.com</a>&nbsp;if
            you wish us to cease processing your data without erasing it
          </li>
          <li>
            To have your data erased &ndash; please contact&nbsp;
            <a href="mailto:info@dewynters.com">info@dewynters.com</a>&nbsp;to
            request deletion of data that we hold about you
          </li>
          <li>
            To data portability &ndash; we process data only to personalise our
            services to you so do not consider it appropriate to pass your data
            to other organisations. Some banks and utilities offer facilities to
            switch accounts, but this is not applicable to our business.
          </li>
          <li>
            To object to processing of your data on the basis of legitimate
            interest or for statistical purposes &ndash; please contact&nbsp;
            <a href="mailto:info@dewynters.com">info@dewynters.com</a>, or amend
            your cookie settings.
          </li>
        </ul>
        <h3>
          <strong>Complaints</strong>
        </h3>
        <p>
          To exercise all relevant rights, queries of complaints please in the
          first instance contact&nbsp;
          <a href="mailto:info@dewynters.com">info@dewynters.com</a>
        </p>
        <p>
          You can contact the Information Commissioners Office on 0303 123 1113
          or via email&nbsp;
          <a href="https://ico.org.uk/global/contact-us/email/">
            https://ico.org.uk/global/contact-us/email/
          </a>
          &nbsp;or at the Information Commissioner's Office, Wycliffe House,
          Water Lane, Wilmslow, Cheshire. SK9 5AF.
        </p>
        <a href="https://www.dewynters.com/">Back to home</a>
      </section>
    </LegalWrapper>
  </Layout>
)

export default PrivacyCookies
